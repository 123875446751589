/**
 * DrvBrand is Shared Component which is used on DrvSlider as content.
 * Contains slide with image.
 *
 * To use this component you need to specify image named item.
 **/

import React from "react";

import './DrvBrand.css';

const DrvBrand = ({item}) => {
    return (
        <img src={item} alt='Brand Logo'/>
    );
}

export default DrvBrand;



