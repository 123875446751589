import styled from "styled-components";

export const TaberContent = styled.div`
      width: 100%;
      align-items: center;
`;

export const TaberContainer = styled.div`
    display: flex;
`;

export const TaberImages = styled.div`
    display: flex;
    align-items: center;
`;

export const CardsPlace = styled.div`
    justify-content: center;  
    flex-direction: column;
    //@media (max-width: 1024px){
    //  display: none;
    //}
`;

export const TaberHeader = styled.div`
      font-size: 30px;
      font-weight: 300;
      color: var(--subtitle-color);
`;
export const ImgDescription = styled.div`
      display: none;
      flex-direction: column;
      align-items: center;
      color: grey;
      @media (max-width: 1024px){
        display: flex;
      }
`;
export const TaberTitle = styled.div`
    font-size: 23px;
    font-weight: 300;
    color: #81898d;
`;

export const TaberText = styled.div`
    font-size: 15px;
    font-weight: 300;
    color: #81898d;
`;