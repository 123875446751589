import React from "react";

import { DrvSquareCardContent, DrvSquareCardTitle, DrvSquareCardBody } from './DrvSquareCardUI.styles'

const DrvSquareCard = ({title, text, click, activeTab}) =>{
    return (
        <DrvSquareCardContent onClick={click} className={`transition-all ${activeTab ? 'active' : 'inactive'}`}>
            <DrvSquareCardTitle>
                {title}
            </DrvSquareCardTitle>
            <DrvSquareCardBody>
                {text}
            </DrvSquareCardBody>
        </DrvSquareCardContent>
    )
}

export default DrvSquareCard;