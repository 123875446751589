import React, {useCallback, useEffect, useState} from "react";

import {Content} from "./Counter.styles";

import Drivers from "../../../images/Home/Drivers.png";
import Trips from "../../../images/Home/Trips.png";
import MilesDriven from "../../../images/Home/MilesDriven.png";
import MilesDrivenWeekly from "../../../images/Home/MilesDrivenWeekly.png";
import { request } from "../../../services/api";
import {formatNumber} from "../../../services/global";


const Counter = () => {
    const [counter, setCounters] = useState({
        drivers: 0,
        certified_drivers: 0,
        trip_numbers: 0,
        miles: parseInt(0),
        last_month_miles: 0
    });

    useEffect(() => {
        fetchDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchDate = useCallback(async () => {
        const data =  await request("GET", "/v1/website/summary/stats");
        const response = data.body;
        const duration = 4000; // Total duration in milliseconds
        const interval = setInterval(() => {
            setCounters(previous => {

                if (previous.drivers >= response.drivers && previous.certified_drivers >= response.certified_drivers && previous.trip_numbers >= response.trip_numbers && previous.miles >= response.miles && previous.last_month_miles >= response.last_month_miles) {
                    clearInterval(interval);
                    return response;
                }
                setTimeout(() => {
                    clearInterval(interval);
                    setCounters({
                        drivers: parseInt(response.drivers),
                        certified_drivers: parseInt(response.certified_drivers),
                        trip_numbers: parseInt(response.trip_numbers),
                        miles: parseInt(response.miles),
                        last_month_miles: parseInt(response.last_month_miles)
                    });
                    return;
                }, duration)
                return {
                    drivers: previous.drivers >= response.drivers ? response.drivers : previous.drivers + 15 * 1,
                    certified_drivers: previous.certified_drivers >= response.certified_drivers ? response.certified_drivers : previous.certified_drivers + 15 * 1,
                    trip_numbers: previous.trip_numbers >= response.trip_numbers ? response.trip_numbers : previous.trip_numbers + 15 * 2050,
                    miles: previous.miles >= response.miles ? response.miles : previous.miles + 15 * 14095,
                    last_month_miles: previous.last_month_miles >= response.last_month_miles ? response.last_month_miles : previous.last_month_miles + 15 * 134
                }
            });
        }, 25);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='container mx-auto flex flex-wrap items-center h-full px-10 sm:px-24 lg:px-32 xl:px-0 py-5'>
            <Content className='basis-full md:basis-1/2 xl:basis-1/4'>
                <img src={Drivers} alt='Drivers' />
                <div className='flex flex-col w-full justify-center' style={{padding: '0px 15px'}}>
                    <div className='counter'>{formatNumber(counter.certified_drivers)}</div>
                    <div>Certified Drivers</div>
                </div>
            </Content>
            <Content className='basis-full md:basis-1/2 xl:basis-1/4'>
                <img src={Trips} alt='Trips'/>
                <div className='flex flex-col w-full justify-center' style={{padding: '0px 15px'}}>
                    <div className='counter'>{formatNumber(counter.trip_numbers)}</div>
                    <div>Drivosity Trips</div>
                </div>
            </Content>
            <Content className='basis-full md:basis-1/2 xl:basis-1/4'>
                <img src={MilesDriven} alt='Miles Driven'/>
                <div className='flex flex-col w-full justify-center' style={{padding: '0px 15px'}}>
                    <div className='counter'>{formatNumber(counter.miles)}</div>
                    <div>Miles Driven</div>
                </div>
            </Content>
            <Content className='basis-full md:basis-1/2 xl:basis-1/4'>
                <img src={MilesDrivenWeekly} alt='Miles Driven Weekly'/>
                <div className='flex flex-col w-full justify-center' style={{padding: '0px 15px'}}>
                    <div className='counter'>{formatNumber(counter.last_month_miles)}</div>
                    <div>Last Month Miles Driven</div>
                </div>
            </Content>
        </div>
    )
}

export default Counter;
