import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  
  & img {
    display: flex;
    height: auto;
    width: 80px;
    padding: 15px 15px;
  }
  
  & .counter {
    font-size: 30px;
  }
`
