import styled from "styled-components"

export const CommentContainer = styled.div`
  padding: 30px 0px;
  background-color: #ecf0f1;
`

export const SliderContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 340px;
  padding: 50px 0px;
`

export const SliderHeader = styled.div`
  text-align: center;
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  color: var(--text-color);
`

export const SubFooterTitle = styled.div`
  font-size: 30px;
  margin-bottom: 20px;
`

export const SubFooterContent = styled.div`
  font-size: 16px;
  line-height: 26px;
`




